import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  // Breadcrumbs,
  // Link
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const JobDetails = () => {
  const { jobrole } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/career/${jobrole}`);
        // const response = await axios.get(`https://superteacher.co.in/api/career/${jobrole}`);
        setJobDetails(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching job details:", err);
        setError(err.response?.data?.message || 'Failed to load job details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobrole]);

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress sx={{ color: '#3a96d7' }} />
        </Box>
      );
    }

    if (error) {
      return (
        <Alert 
          severity="error" 
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => navigate('/career')}
            >
              Back to Jobs
            </Button>
          }
        >
          {error}
        </Alert>
      );
    }

    return (
      <>
        {/* Breadcrumb Navigation */}
        {/* <Box sx={{ mb: 4 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component="button"
              onClick={() => navigate('/career')}
              sx={{ 
                textDecoration: 'none', 
                color: '#666',
                '&:hover': { color: '#3a96d7' }
              }}
            >
              Careers
            </Link>
            <Typography color="text.primary">
              {jobrole.split('-').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ')}
            </Typography>
          </Breadcrumbs>
        </Box> */}

        {/* Back Button */}
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/career')}
          sx={{ mb: 4 }}
        >
          Back to Jobs
        </Button>

        {jobDetails ? (
          <Box
            sx={{
              '& img': { maxWidth: '100%', height: 'auto' },
              '& table': { 
                width: '100%', 
                borderCollapse: 'collapse', 
                mb: 2,
                border: '1px solid #ddd'
              },
              '& th, & td': { 
                border: '1px solid #ddd', 
                p: 2,
                textAlign: 'left'
              },
              '& th': {
                backgroundColor: '#f5f5f5'
              },
              '& ul, & ol': { pl: 4 },
              '& li': { mb: 1 },
              '& h1, & h2, & h3, & h4, & h5, & h6': { 
                color: '#2c3e50',
                my: 2
              },
              '& p': { 
                mb: 2,
                lineHeight: 1.6
              },
              '& a': {
                color: '#3a96d7',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
            dangerouslySetInnerHTML={{ __html: jobDetails.KnowMore }}
          />
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', color: '#666' }}>
            Job details not found.
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>

      {/* Main Content */}
      <Box sx={{ 
        pt: { xs: 16, sm: 17, md: 18 },
        px: { xs: 2, sm: 4, md: 6 },
        minHeight: '100vh'
      }}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {renderContent()}
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default JobDetails;