import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Assessment as AssessmentIcon,
  ExpandLess,
  ExpandMore,
  Close as CloseIcon,
} from "@mui/icons-material";

const drawerWidth = 240;

const Sidebar = ({ open, onClose }) => {
  const [reportsOpen, setReportsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const drawerContent = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          minHeight: { xs: 56, sm: 64 },
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          Navigation
        </Typography>
        {isMobile && (
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <List sx={{ p: 2 }}>
        <ListItemButton sx={{ mb: 1, borderRadius: 1 }}
        onClick={() => navigate("/admin-dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        <ListItemButton
          sx={{ mb: 1, borderRadius: 1 }}
          onClick={() => navigate("/admin-dashboard/create-courses")}
        >
          <ListItemIcon>
            <PeopleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Create Courses" />
        </ListItemButton>

        <ListItemButton
          onClick={() => setReportsOpen(!reportsOpen)}
          sx={{ mb: 1, borderRadius: 1 }}
        >
          <ListItemIcon>
            <AssessmentIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Edit Courses" />
          {reportsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              {
                text: "Carousel View",
                path: "/admin-dashboard/edit-courses/carousel-cards",
              },
              {
                text: "Color Cards",
                path: "/admin-dashboard/edit-courses/color-cards",
              },
              {
                text: "Course Cards",
                path: "/admin-dashboard/edit-courses/course-cards",
              },
            ].map((item) => (
              <ListItemButton
                key={item.text}
                sx={{ pl: 4, py: 1, borderRadius: 1 }}
                onClick={() => navigate(item.path)}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
    </>
  );

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          border: "none",
          borderRight: "2px solid rgba(0, 0, 0, 0.12)", // Added vertical border
          ...(!isMobile &&
            !open && {
              width: 0,
              visibility: "hidden",
            }),
        },
        width: !isMobile && open ? drawerWidth : 0,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default Sidebar;
