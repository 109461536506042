import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Tabs, Tab, Alert, Button } from '@mui/material';
import AddEntryForm from './B_BookPage1';
import ViewEntriesTable from './C_BookPage2';
import CSVUploadPage from './D_BookPage3';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const BookkeepingSystem = () => {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/book`);
      if (!response.ok) {
        throw new Error('Failed to fetch entries');
      }
      const data = await response.json();
      setEntries(data);
    } catch (err) {
      setError('Failed to load entries. Please try again later.');
      console.error('Error fetching entries:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEntry = async (newEntry) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/book`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEntry),
      });
      if (!response.ok) {
        throw new Error('Failed to add entry');
      }
      await fetchEntries(); // Refresh the entries list
    } catch (err) {
      throw err;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const handleClickManageParameter = () => {
    navigate('/manage-parameter');
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom sx={{textAlign:'center'}}>
        BOOK-KEEPING
      </Typography>

      <Box width='full' sx={{display:'flex', direction:'row', justifyContent:'end'}}>    
      <Button sx={{ 
        backgroundColor: '#f5f5f5', 
        color:'black',
        '&:hover': { backgroundColor: '#3a96d7', color:'white' } 
      }}
      onClick={handleClickManageParameter}>
        Manage Parameters
      </Button>
      </Box>


      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="bookkeeping tabs">
          <Tab label="Add Entry" />
          <Tab label="View Entries" />
          <Tab label="Upload CSV" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <AddEntryForm onAddEntry={handleAddEntry} />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <ViewEntriesTable entries={entries} isLoading={isLoading} />
      </TabPanel>

      // ... (added a new TabPanel)

        <TabPanel value={tabValue} index={2}>
        <CSVUploadPage />
        </TabPanel>

    </Container>
  );
};

export default BookkeepingSystem;