import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Grid, Container, useMediaQuery } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';  // Added Button import


// Styled Button Component
const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  borderRadius: 20,
  padding: theme.spacing(1, 3),
  minHeight: 40,
  fontSize: theme.typography.pxToRem(15),
  fontFamily: 'Comfortaa, sans-serif',
  fontWeight: 'bold',
  textTransform: 'none',
  backgroundColor: '#fecc00',
  color: 'white',
  boxShadow: '0 2px 4px rgba(254, 204, 0, 0.2)',
  transition: 'all 0.3s ease',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.75, 2),
    fontSize: theme.typography.pxToRem(14),
    minHeight: 36,
  },

  '&:hover': {
    backgroundColor: '#fecc00',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(254, 204, 0, 0.3)',
  },

  '&:active': {
    transform: 'translateY(0)',
    boxShadow: '0 2px 4px rgba(254, 204, 0, 0.2)',
  },

  '&.disabled': {
    backgroundColor: '#feeea3',
    color: 'rgba(255, 255, 255, 0.8)',
    cursor: 'not-allowed',
  },
}));

// Styled Navigation Button
const NavButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,0.9)',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease',
  
  [theme.breakpoints.down('md')]: {
    width: 35,
    height: 35,
    '& svg': {
      fontSize: '1.5rem',
    },
  },

  [theme.breakpoints.up('md')]: {
    width: 45,
    height: 45,
    '& svg': {
      fontSize: '2rem',
    },
  },

  '&:hover': {
    backgroundColor: 'rgba(255,255,255,1)',
    transform: 'scale(1.1)',
  },
}));

export function StButton2({ 
  text, 
  link, 
  disabled = false,
  startIcon,
  endIcon,
  onClick,
  type = 'button',
  fullWidth = false,
  className,
  ariaLabel,
}) {
  return (
    <StyledButton
      href={link}
      className={className}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      type={type}
      fullWidth={fullWidth}
      aria-label={ariaLabel || text}
      role={link ? 'link' : 'button'}
    >
      {text}
    </StyledButton>
  );
}

export function Important() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [carouselData, setCarouselData] = React.useState([]);
  const [swiper, setSwiper] = React.useState(null);

  React.useEffect(() => {
    console.log("This is carousel data", carouselData)
    console.log("This is carousel length", carouselData.length)
    console.log("This is carousel array.carouseldata", Array.isArray(carouselData))
  }, [carouselData])
  
  React.useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/home/carouseldata`)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        const data = await response.json()
        setCarouselData(data)
      } catch (e) {
        console.error('Error fetching Carousel Data:', e)
      }
    }

    fetchCarouselData()
  }, [])



  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handleBack = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleSlideChange = () => {
    if (swiper) {
      setActiveStep(swiper.realIndex);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
      <Box 
        sx={{ 
          my: { xs: 2, sm: 3, md: 4 },
          position: 'relative',
          '&:hover .navigation-arrows': {
            opacity: 1
          }
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            // borderRadius: { xs: 2, sm: 3, md: 4 },
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            overflow: 'hidden',
            position: 'relative',
            minHeight: { xs: 'auto', sm: '400px', md: '400px' },
            borderRadius:'10px'
          }}
        >
          {Array.isArray(carouselData) && carouselData.length > 0 ? (
            <Swiper
              onSwiper={setSwiper}
              onSlideChange={handleSlideChange}
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={true}
              allowTouchMove={true}
              speed={500}
            >
              {carouselData.map((step) => (
                <SwiperSlide key={step.courseId}>
                  <Grid 
                    container 
                    direction={isMobile ? 'column-reverse' : 'row'}
                    sx={{ height: '100%' }}
                  >
                    {/* Left side - Description Box */}
                    <Grid item xs={12} md={6}>
                      <Box 
                        sx={{ 
                          px: { xs: 2, sm: 3, md: 4 },
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          // borderRight: { md: '1px solid rgba(0,0,0,0.08)' },
                          // borderBottom: { xs: '1px solid rgba(0,0,0,0.08)', md: 'none' }
                        }}
                      >
                        <Typography 
                          variant="h4" 
                          component="h2" 
                          sx={{ 
                            mb: { xs: 2, md: 3 },
                            fontWeight: 'bold',
                            color: '#333',
                            fontFamily:'Comfortaa',
                            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
                          }}
                        >
                          {step.courseName}
                        </Typography>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            mb: { xs: 2, md: 4 },
                            color: '#666',
                            lineHeight: 1.7,
                            maxHeight: { xs: '200px', md: '200px' },
                            overflow: 'hidden',
                            fontSize: { xs: '0.875rem', sm: '1rem' }
                          }}
                        >
                          {parse(step.carouselDescription || '')}
                        </Typography>
                        <Box sx={{ mt: { xs: 1, md: 2 }, textAlign:'center' }}>
                        <StButton2 
                          text="Explore" 
                          link={`${process.env.REACT_APP_API_URL}/offerings/students/coding`} 
                        />                        
                        </Box>
                      </Box>
                    </Grid>

                    {/* Right side - Image Box */}
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          height: '100%',
                          p: { xs: 2, sm: 3, md: 4 }
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: { xs: '200px', sm: '300px', md: '400px' },
                            // borderRadius: { xs: 2, sm: 3 },
                            overflow: 'hidden',
                            // boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                          }}
                        >
                          <Box
                            component="img"
                            src={step.imageUrl}
                            alt={step.courseName}
                            sx={{
                              width: '100%',        
                              objectFit: 'cover',
                              objectPosition: 'center',
                              borderRadius:'10px'
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Box sx={{ p: 4, textAlign: 'center' }}>
              <Typography>Loading carousel data...</Typography>
            </Box>
          )}

          {/* Navigation Arrows */}
          {!isMobile && (
            <>
              <NavButton
                onClick={handleBack}
                className="navigation-arrows"
                sx={{
                  position: 'absolute',
                  left: { xs: 8, sm: 12, md: 16 },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.3s, transform 0.3s',
                  zIndex: 2
                }}
              >
                <KeyboardArrowLeft />
              </NavButton>
              <NavButton
                onClick={handleNext}
                className="navigation-arrows"
                sx={{
                  position: 'absolute',
                  right: { xs: 8, sm: 12, md: 16 },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.3s, transform 0.3s',
                  zIndex: 2
                }}
              >
                <KeyboardArrowRight />
              </NavButton>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Important;