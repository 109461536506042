import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Checkbox, Button, Grid, Paper,
  FormControlLabel, Alert, MenuItem, CircularProgress
} from '@mui/material';

const initialEntryState = {
  FY: '',
  Month: '',
  RecordSN: '',
  TransactionID: '',
  ValueDate: '',
  TxnPostedDate: '',
  ChequeNo: '',
  Description: '',
  Description2: '',
  CrDr: '',
  Balance: '',
  TransactionAmount: '',
  PartyName: '',
  PartyType: '',
  ServiceType: '',
  ProductName: '',
  SalesPerson: '',
  ModeOfTransaction: '',
  Cat0: '',
  Cat1: '',
  Cat2: '',
  Cat3: '',
  Cat4: '',
  Cat5: '',
  Department: '',
  Product: '',
  CompleteDescription: '',
  InvoiceNumber: '',
  InvoiceLinks: '',
  Comment: '',
  TDSIfApplicable: false
};

const AddEntryForm = ({ onAddEntry }) => {
  const [newEntry, setNewEntry] = useState(initialEntryState);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [isFetchingMonths, setIsFetchingMonths] = useState(false);

  useEffect(() => {
    fetchYears();
  }, []);

  const fetchYears = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/years`);
      const data = await response.json();
      setYears(data);
    } catch (error) {
      console.error('Error fetching years:', error);
      setError('Failed to fetch years. Please try again.');
    }
  };

  const fetchMonths = async (fyId) => {
    setIsFetchingMonths(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/months/by-year/${fyId}`);
      const data = await response.json();
      setMonths(data);
    } catch (error) {
      console.error('Error fetching months:', error);
      setError('Failed to fetch months. Please try again.');
    } finally {
      setIsFetchingMonths(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewEntry(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (name === 'FY') {
      setNewEntry(prev => ({ ...prev, Month: '' }));
      fetchMonths(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await onAddEntry(newEntry);
      setNewEntry(initialEntryState);
    } catch (err) {
      setError('Failed to add entry. Please try again.');
      console.error('Error adding new entry:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(initialEntryState).map((key) => (
            <Grid item xs={12} sm={6} key={key}>
              {key === 'TDSIfApplicable' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newEntry[key]}
                      onChange={handleInputChange}
                      name={key}
                    />
                  }
                  label={key}
                />
              ) : key === 'FY' ? (
                <TextField
                  select
                  fullWidth
                  label={key}
                  name={key}
                  value={newEntry[key]}
                  onChange={handleInputChange}
                  variant="outlined"
                >
                  {years.map((year) => (
                    <MenuItem key={year._id} value={year._id}>
                      {year.financialYear}
                    </MenuItem>
                  ))}
                </TextField>
              ) : key === 'Month' ? (
                <TextField
                  select
                  fullWidth
                  label={key}
                  name={key}
                  value={newEntry[key]}
                  onChange={handleInputChange}
                  variant="outlined"
                  disabled={!newEntry.FY || isFetchingMonths}
                >
                  {isFetchingMonths ? (
                    <MenuItem disabled>
                      <CircularProgress size={20} />
                    </MenuItem>
                  ) : (
                    months.map((month) => (
                      <MenuItem key={month._id} value={month._id}>
                        {month.month}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              ) : (
                <TextField
                  fullWidth
                  label={key}
                  name={key}
                  value={newEntry[key]}
                  onChange={handleInputChange}
                  type={key.includes('Date') ? 'date' : 'text'}
                  variant="outlined"
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? 'Adding...' : 'Add Entry'}
        </Button>
      </form>
    </Paper>
  );
};

export default AddEntryForm;