// DashboardPage.jsx
import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Grid, 
  Container,
} from '@mui/material';
import AdminLayout from './Admin_Layout/AdminLayout';

const DashboardPage = () => {
  const dashboardContent = (
    <Box
      sx={{
        p: { xs: 2, sm: 3 },
        pt: { xs: 8, sm: 9 },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3, 
                bgcolor: 'background.paper',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Typography variant="h4" gutterBottom>
                Welcome to Dashboard
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                This is your responsive admin dashboard. The layout adjusts smoothly
                as you toggle the sidebar. Notice how the navbar and content area
                width changes along with the sidebar state.
              </Typography>
              <Grid container spacing={2}>
                {[1, 2, 3].map((i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <Paper 
                      elevation={0}
                      sx={{ 
                        p: 2,
                        bgcolor: 'grey.50',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Card {i}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This card demonstrates the responsive grid layout.
                        The content adjusts based on both screen size and
                        sidebar state.
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return (
    <AdminLayout>
      {dashboardContent}
    </AdminLayout>
  );
};

export default DashboardPage;