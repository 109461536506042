import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardMedia,
  FormControlLabel,
  Switch
} from '@mui/material';
import AdminLayout from './Admin_Layout/AdminLayout';

const courseNames = [
  "Super Computer Curriculum",
  "CSAW 2023 - A Global Conclave on Emerging trends in Computer Science Education",
  "Course 3",
  "Course 4",
  "Course 5",
  "Course 6"
];

const categories = ["Category 1", "Category 2", "Category 3"];
const subcategories = ["Web Development", "Mobile Development", "Cloud Computing", "AI/ML", "DevOps", "Data Science"];
const courseForOptions = [
  { value: 'Teachers', label: 'Teachers' },
  { value: 'Students', label: 'Students' },
  { value: 'Schools', label: 'Schools' }
];

const CreateCoursePage = () => {
  const [courseData, setCourseData] = useState({
    imageUrl: '',
    courseId: '',
    courseName: '',
    category: '',
    subCategory: '',
    courseFor: '',
    description: '',
    carouselDescription: '',
    cardsDescription: '',
    duration: '',
    startDate: '',
    endDate: '',
    paidCourse: false,
    fees: 0,
    knowMore: '',
    domain: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData(prev => ({
        ...prev,
        [name]: name === 'fees' ? Number(value) || 0 : value
    }));
};

  const handlePaidCourseChange = (e) => {
    const isChecked = e.target.checked;
    setCourseData(prev => ({
        ...prev,
        paidCourse: isChecked,
        fees: isChecked ? (prev.fees || 0) : 0
    }));
};
  

const handleSubmit = async (e) => {
  e.preventDefault();
  
  const formattedData = {
      ...courseData,
      paidCourse: Boolean(courseData.paidCourse),
      fees: courseData.paidCourse ? Number(courseData.fees) : 0
  };

  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/courses/create`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formattedData)
      });
      
      const result = await response.json();
      if (!response.ok) {
          console.log('Error details:', result);
          throw new Error(result.message || 'Failed to create course');
      }

      alert('Course created successfully!');
      console.log('Created course:', result);
      
  } catch (error) {
      console.error('Error creating course:', error);
      alert(`Error creating course: ${error.message}`);
  }
};

  return (
    <AdminLayout>
      <Box sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 8, sm: 9 } }}>
        <Container maxWidth="lg">
          <Paper
            elevation={0}
            sx={{
              p: 3,
              bgcolor: 'background.paper',
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Typography variant="h4" gutterBottom>
              Create New Course
            </Typography>
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                {/* Image URL and Preview Section */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Image URL"
                    name="imageUrl"
                    value={courseData.imageUrl}
                    onChange={handleInputChange}
                    placeholder="Enter image URL"
                    sx={{ mb: 2 }}
                  />
                  {courseData.imageUrl && (
                    <Card
                      elevation={0}
                      sx={{
                        maxWidth: 400,
                        margin: '0 auto',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 2,
                        overflow: 'hidden'
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={courseData.imageUrl}
                        alt="Course preview"
                        sx={{
                          height: 200,
                          objectFit: 'cover'
                        }}
                        onError={(e) => {
                          e.target.src = 'https://via.placeholder.com/400x200?text=Invalid+Image+URL';
                        }}
                      />
                    </Card>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Course ID"
                    name="courseId"
                    value={courseData.courseId}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Course Name</InputLabel>
                    <Select
                      name="courseName"
                      value={courseData.courseName}
                      onChange={handleInputChange}
                      label="Course Name"
                    >
                      {courseNames.map((name) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={courseData.category}
                      onChange={handleInputChange}
                      label="Category"
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Subcategory</InputLabel>
                    <Select
                      name="subCategory"
                      value={courseData.subCategory}
                      onChange={handleInputChange}
                      label="Subcategory"
                    >
                      {subcategories.map((subcat) => (
                        <MenuItem key={subcat} value={subcat}>{subcat}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Course For</InputLabel>
                    <Select
                      name="courseFor"
                      value={courseData.courseFor}
                      onChange={handleInputChange}
                      label="Course For"
                    >
                      {courseForOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Domain</InputLabel>
                    <Select
                      name="domain"
                      value={courseData.domain}
                      onChange={handleInputChange}
                      label="Domain"
                      disabled={courseData.courseFor !== 'Students'}
                    >
                      <MenuItem value="coding">Coding</MenuItem>
                      <MenuItem value="cbse subjects">CBSE Subjects</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={courseData.description}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    required
                    inputProps={{ maxLength: 250 }}
                    helperText={`${courseData.description.length}/250 characters`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Carousel Description"
                    name="carouselDescription"
                    value={courseData.carouselDescription}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    required
                    inputProps={{ maxLength: 250 }}
                    helperText={`${courseData.carouselDescription.length}/250 characters`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Cards Description"
                    name="cardsDescription"
                    value={courseData.cardsDescription}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                    required
                    inputProps={{ maxLength: 200 }}
                    helperText={`${courseData.cardsDescription.length}/200 characters`}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Duration (hours)"
                    name="duration"
                    type="number"
                    value={courseData.duration}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Start Date"
                        name="startDate"
                        type="date"
                        value={courseData.startDate}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="End Date"
                        name="endDate"
                        type="date"
                        value={courseData.endDate}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        inputProps={{
                          min: courseData.startDate
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={courseData.paidCourse}
                        onChange={handlePaidCourseChange}
                        name="paidCourse"
                      />
                    }
                    label="Paid Course"
                  />
                </Grid>

                {courseData.paidCourse && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Fees"
                      name="fees"
                      type="number"
                      value={courseData.fees}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Know More Description (HTML)"
                    name="knowMore"
                    value={courseData.knowMore}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                  >
                    Create Course
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
    </AdminLayout>
  );
};

export default CreateCoursePage;