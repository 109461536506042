import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box'; // or the correct path if it's custom
import { FolderBox } from './FolderBox';
// import foldersDB from './path-to-foldersDB'; // adjust path as needed

export function FolderSection() {
    const [folderID, setFolderID] = useState(0);
    const [folders, setFolders] = useState([]);

    // useEffect(() => {
    //     const filteredFolders = foldersDB.filter((folder) => folder.parentFolder === folderID);
    //     setFolders(filteredFolders);
    // }, [folderID]);

    return (
        <Box sx={{ margin: '100px' }}>
            {folders.map((folder) => (
                <FolderBox 
                    key={folder._id} 
                    _id={folder._id} 
                    folderName={folder.folderName}
                />
            ))}
        </Box>
    );
}
