import React, { useState } from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Card, CardContent, CardHeader, Typography, TextField, Button, Box, Snackbar, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Person as PersonIcon, Lock as LockIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const StyledCard = styled(Card)(({ theme }) => ({
  width: 360,
  height: 400,
  borderRadius: 20,
  boxShadow: theme.shadows[10],
  position: 'relative',
  overflow: 'hidden',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  position: 'relative',
  zIndex: 1,
}));



function LoginCard() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsLoading(true);
  
    if (!username || !password) {
        setError("Username and password are required");
        setIsLoading(false);
        return;
    }

  
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/api/login`;
  
    try {
        const response = await axios.post(apiEndpoint, { username, password });
        setSuccess(response.data.message);
        
        
      localStorage.setItem("token", response.data.token);
       localStorage.setItem("userRole", response.data.user.role); // Store the user role
        setUsername("");
        setPassword("");
        
        
        const url = `/admin-dashboard`
        navigate(url);
        //navigate(`/${userType}-dashboard`);
        
    } catch (error) {
        console.error("Login error:", error);
        if (error.response) {
            setError(error.response.data.message || "An error occurred during login");
        } else if (error.request) {
            setError("No response received from the server. Please try again.");
        } else {
            setError("An unexpected error occurred. Please try again.");
        }
    } finally {
        setIsLoading(false);
    }
  };
  

  return (
    <StyledCard>
      <ContentBox>
        <CardHeader
          title={
            <Typography variant="h5" component="div" align="center" gutterBottom>
              LOGIN
            </Typography>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <Box component="form" onSubmit={handleLogin} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              placeholder="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                startAdornment: <PersonIcon sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />
            <TextField
              fullWidth
              type="password"
              placeholder="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: <LockIcon sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
               {isLoading ? <CircularProgress size={24} /> : "Sign In"}
            </Button>


                  {error && (
                        <Typography color="error" variant="body2" align="center" gutterBottom>
                            {error}
                        </Typography>
                    )}
                    
                    {success && (
                        <Typography color="success" variant="body2" align="center" gutterBottom>
                            {success}
                        </Typography>
                    )}

          </Box>
        </CardContent>
      </ContentBox>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </StyledCard>
  );
}



export function LoginPage() {
  return(
    <>
      <Header/>
      <Box sx={{
        m:'30px', 
        mt:'200px', 
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center', 
        justifyContent:'center',
        height: 'calc(100vh - 200px)' // Adjust based on your layout
      }}>
        <LoginCard/>
      </Box>
    </>
  )
}

