import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import parse from 'html-react-parser';
import axios from 'axios';

const EventDisplay = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/events`);
        // const response = await axios.get('https://superteacher.co.in/api/events');
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return null; // Optional: replace with a loading spinner if needed
  }

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        py: 4,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }}
    >
      {events.map((event, index) => (
        <Box 
          key={event._id || index} 
          sx={{ 
            mb: 8,
            '&:last-child': { mb: 0 }
          }}
        >
          {/* Image Box */}
          <Box 
            sx={{ 
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <img
              src={event.imageURL}
              alt={event.header}
              style={{
                maxWidth: '100%',
                height: 'auto', // Adjusts image height based on width to maintain aspect ratio
                objectFit: 'cover', // Ensures proper image cropping
                borderRadius: '8px'
              }}
            />
          </Box>

          {/* Header */}
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 3,
              fontWeight: 600,
              color: '#2c3e50',
              textAlign: 'left' 
            }}
          >
            {event.header}
          </Typography>

          {/* Description with Responsive Styles */}
          <Box
            sx={{
              '& h1': {
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                color: '#2c3e50',
                mb: 2
              },
              '& h2': {
                fontSize: { xs: '1rem', sm: '1.25rem' },
                color: '#2c3e50',
                mb: 2
              },
              '& p': {
                fontSize: { xs: '0.85rem', sm: '1rem' },
                color: '#4a5568',
                mb: 2,
                lineHeight: 1.7
              },
              '& ul, & ol': {
                mb: 2,
                pl: 3
              },
              '& li': {
                fontSize: { xs: '0.85rem', sm: '1rem' },
                color: '#4a5568',
                mb: 1
              },
              '& a': {
                color: '#3a96d7',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            }}
          >
            {parse(event.description)}
          </Box>
        </Box>
      ))}
    </Container>
  );
};

export default EventDisplay;
