import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card as MuiCard,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Stack,
  Snackbar
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RegistrationForm = () => {
  const { coursesid } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    city: '',
    phoneNumber: '',
    organization: '',
    email: '',
    courseName: '',
    name: '', // Added name field
    preferredDateTime: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/courses-details/${coursesid}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch course details');
        }
        
        const data = await response.json();
        
        if (!data.data) {
          throw new Error('Course data is empty');
        }

        setFormData(prev => ({
          ...prev,
          courseName: data.data.courseName
        }));
      } catch (error) {
        console.error("Error fetching course details:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (coursesid) {
      fetchCourseDetails();
    }
  }, [coursesid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Clear any previous errors
    
    try {
      // Prepare the data to match your schema
      const registrationData = {
        name: formData.name,
        city: formData.city,
        phoneNumber: formData.phoneNumber,
        schoolOrOrganization: formData.organization,
        email: formData.email,
        courseName: formData.courseName,
        preferredDateTime: formData.preferredDateTime
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Registration failed');
      }

      // Set success message and show alert
      setSuccessMessage('Registration successful!');
      setShowSuccessAlert(true);
      
      // Clear form after successful submission
      setFormData({
        name: '',
        city: '',
        phoneNumber: '',
        organization: '',
        email: '',
        courseName: formData.courseName, // Keep the course name
        preferredDateTime: ''
      });

      // Optional: Redirect after a delay
      setTimeout(() => {
        navigate('/');
      }, 2000);

    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress sx={{ color: '#3a96d7' }} />
      </Box>
    );
  }

  return (
    <>
      <Button 
        startIcon={<ArrowBackIcon />} 
        onClick={() => navigate('/')}
        sx={{ 
          mb: 4,
          color: '#3a96d7',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'rgba(58, 150, 215, 0.04)'
          }
        }}
      >
        Back to Courses
      </Button>

      {/* Error Alert */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      {/* Success Snackbar */}
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={4000}
        onClose={() => setShowSuccessAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowSuccessAlert(false)} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <MuiCard
        sx={{
          borderRadius: 2,
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '4px',
            height: '100%',
            backgroundColor: '#3a96d7',
          },
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
          <Typography variant="h5" component="h2" align="center" gutterBottom sx={{ 
            fontWeight: 600,
            color: '#2c3e50',
            mb: 4
          }}>
            Course Registration
          </Typography>

          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <TextField
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                type="tel"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <TextField
                label="School/Organization Name"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                type="email"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <TextField
                label="Course Name"
                name="courseName"
                value={formData.courseName}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                variant="outlined"
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <TextField
                label="Preferred Date and Time"
                name="preferredDateTime"
                value={formData.preferredDateTime}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#3a96d7',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#3a96d7',
                    },
                  },
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                sx={{
                  bgcolor: '#fecc00',
                  color: 'white',
                  py: 1.5,
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  '&:hover': {
                    bgcolor: '#e3b800',
                  },
                  textTransform: 'none',
                  borderRadius: 2,
                  boxShadow: '0 2px 8px rgba(254, 204, 0, 0.3)',
                }}
              >
                Submit Registration
              </Button>
            </Stack>
          </form>
        </CardContent>
      </MuiCard>
    </>
  );
};

export default RegistrationForm;