import { Grid, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StButton2 } from "./A_Carousel"; // Adjust path if needed
import { yellow } from "@mui/material/colors";

export function AboutUsHome() {
  const [about, setAbout] = useState("SuperTeacher Edureforms Pvt Ltd is a team of seasoned academicians and educators, united under the belief that there should be no hard line between academic skills and real-life skills. SuperTeacher Team is dedicated to provide educational services and support aligned to 21st-century requirements. It leaves no stone unturned to create the best possible experience and value for their clients. As a result, SuperTeacher Edureforms has successfully conducted high-quality training for their clients, who are teachers and students from more than 550 schools from India, South-East Asia, Africa, North America and the Middle East.\n\n It was founded under the core principles of competency-based learning and interdisciplinary approach in coding, physical computing and STEM / STEAM education. SuperTeacher is backed by a well-designed curriculum, scientific assessment framework and a team of talented academicians and trainers.\n\n Super Teacher Edureforms Pvt Ltd has trained over 20,000 students on Computer Programming, Development, Artificial Intelligence, Robotics and Drone Technology : and the count is still growing!");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/about`);
        const data = await response.json();
        //setAbout(data.Description); // Assuming "message" is the desired property
        console.log('data...')
        console.log(about)
      } catch (error) {
        console.log("Error fetching data:", error.message);
        //setAbout(error.message)
        // Handle error gracefully, e.g., set a loading state
      }
    };

    fetchData();
  }, []);

  return (
    <Box bgcolor={'#f5f5f5'}>
      <Box sx={{ mx: { xs: "3%", md: "8%" },  mb: { xs: "50px", md: "50px" }, py:'20px' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={8} sx={{ px: { xs: 2, md: 5 } }}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <p>
                {/* Your Vision Text */}
              </p>
              <Box sx={{FontFamily:'Roboto', 
                color:'#424242',
                textAlign:'justify',
                mb:2}}> {about} </Box>
              
              <StButton2 text="Know More" link="/about" />
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container direction="row" alignItems="center" sx={{ justifyContent: { xs: "center", md: "end" } }}>
              <Grid>
                <Box
                  component="img"
                  sx={{ height: 255 }}
                  src="https://superteacher.in/public/frontend/kids/images/teacher_promo.png"
                  alt="About Us Image"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}