import React, { useState, useEffect } from 'react';
import AdminLayout from './Admin_Layout/AdminLayout';
import {
  DragIndicator,
  Category,
  Sort,
  Visibility,
  Edit as EditIcon, 
  Save as SaveIcon
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Alert
} from '@mui/material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './Admin_Layout/SortableItems';

const CourseCardDrag = () => {
  const [courses, setCourses] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/getAllcourses`);
      const data = await response.json();
      
      // Sort by homePage order instead of colorCard
      const sortedData = data.sort((a, b) => a.display.homePage.order - b.display.homePage.order);
      setCourses(sortedData);
      setLoading(false);
    } catch (error) {
      setAlert({
        open: true,
        message: 'Failed to fetch courses',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  const getVisibleCourses = () => {
    return courses
      .filter(course => course.display.homePage.isVisible)
      .slice(0, 3);
  };

  const getOtherCourses = () => {
    const visibleIds = getVisibleCourses().map(course => course.courses_id);
    return courses.filter(course => !visibleIds.includes(course.courses_id));
  };

  const updateCourse = async (courseId, newData) => {
    try {
      const currentCourse = courses.find(c => c.courses_id === courseId);
      
      const updatedDisplay = {
        ...currentCourse.display,
        homePage: {
          ...currentCourse.display.homePage,
          ...newData.display.homePage
        }
      };

      // Using the courses-cards endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/courses-cards/${courseId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          display: updatedDisplay
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update course');
      }
      return await response.json();
    } catch (error) {
      console.error(`Error updating course ${courseId}:`, error);
      throw error;
    }
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event) => {
    setActiveId(null);
    const { active, over } = event;
    
    if (!over || active.id === over.id) return;

    try {
      const activeCourse = courses.find(c => c.courses_id.toString() === active.id);
      const overCourse = courses.find(c => c.courses_id.toString() === over.id);

      const visibleCourses = getVisibleCourses();
      const isFromVisible = visibleCourses.some(c => c.courses_id.toString() === active.id);
      const isToVisible = visibleCourses.some(c => c.courses_id.toString() === over.id);

      // Using homePage order instead of colorCard
      const activeOrder = activeCourse.display.homePage.order;
      const overOrder = overCourse.display.homePage.order;

      let newActiveVisibility = activeCourse.display.homePage.isVisible;
      let newOverVisibility = overCourse.display.homePage.isVisible;

      if (!isFromVisible && isToVisible && visibleCourses.length < 3) {
        newActiveVisibility = true;
      } else if (isFromVisible && !isToVisible) {
        newActiveVisibility = false;
      }

      await updateCourse(activeCourse.courses_id, {
        display: {
          homePage: {
            isVisible: newActiveVisibility,
            order: overOrder
          }
        }
      });

      await updateCourse(overCourse.courses_id, {
        display: {
          homePage: {
            isVisible: newOverVisibility,
            order: activeOrder
          }
        }
      });

      await fetchCourses();

      setAlert({
        open: true,
        message: 'Courses updated successfully',
        severity: 'success'
      });
    } catch (error) {
      setAlert({
        open: true,
        message: `Failed to update courses: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const CourseCard = ({ course }) => (
    <Card
      sx={{
        display: 'flex',
        position: 'relative',
        transition: 'all 200ms cubic-bezier(0.2, 0, 0, 1)',
        height: '80px',
        '&:hover': {
          boxShadow: isEditing ? 4 : 1,
          transform: isEditing ? 'translateY(-2px)' : 'none',
        }
      }}
    >
      {isEditing && (
        <Box
          className="drag-handle"
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '40px',
            bgcolor: 'rgba(0, 0, 0, 0.05)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'grab',
            '&:active': { cursor: 'grabbing' },
            zIndex: 2,
          }}
        >
          <DragIndicator sx={{ fontSize: 20, color: 'text.secondary' }} />
        </Box>
      )}
      <CardContent sx={{ display: 'flex', alignItems: 'center', width: '100%', pl: isEditing ? 6 : 2 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" noWrap>{course.courseName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Category fontSize="small" />
              {course.category}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Sort fontSize="small" />
              Order: {course.display.homePage.order}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Visibility fontSize="small" />
              {course.display.homePage.isVisible ? 'Visible' : 'Hidden'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <AdminLayout>
        <Box sx={{ pt: 10 }}>
          <Box p={3}>
            <Typography>Loading...</Typography>
          </Box>
        </Box>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <Box sx={{ pt: 10 }}>
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h4">Course Cards</Typography>
            <Button
              variant="contained"
              color={isEditing ? "success" : "primary"}
              startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? "Save Changes" : "Edit Courses"}
            </Button>
          </Box>

          <Paper sx={{ p: 3 }}>
            {isEditing ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Visible Cards (Top 3)
                  </Typography>
                  <Box mb={3}>
                    <SortableContext
                      items={getVisibleCourses().map(c => c.courses_id.toString())}
                      strategy={verticalListSortingStrategy}
                    >
                      <Grid container spacing={1}>
                        {getVisibleCourses().map((course) => (
                          <Grid item xs={12} key={course.courses_id}>
                            <SortableItem id={course.courses_id.toString()}>
                              <CourseCard course={course} />
                            </SortableItem>
                          </Grid>
                        ))}
                      </Grid>
                    </SortableContext>
                  </Box>

                  <Divider sx={{ my: 4 }} />

                  <Typography variant="h6" gutterBottom>
                    Other Courses
                  </Typography>
                  <SortableContext
                    items={getOtherCourses().map(c => c.courses_id.toString())}
                    strategy={verticalListSortingStrategy}
                  >
                    <Grid container spacing={1}>
                      {getOtherCourses().map((course) => (
                        <Grid item xs={12} key={course.courses_id}>
                          <SortableItem id={course.courses_id.toString()}>
                            <CourseCard course={course} />
                          </SortableItem>
                        </Grid>
                      ))}
                    </Grid>
                  </SortableContext>
                </Box>

                <DragOverlay>
                  {activeId ? (
                    <Box sx={{ width: '100%', opacity: 0.9 }}>
                      <CourseCard 
                        course={courses.find(c => c.courses_id.toString() === activeId)}
                      />
                    </Box>
                  ) : null}
                </DragOverlay>
              </DndContext>
            ) : (
              <>
                <Typography variant="h6" gutterBottom>
                  Visible Cards (Top 3)
                </Typography>
                <Grid container spacing={1} sx={{ mb: 4 }}>
                  {getVisibleCourses().map((course) => (
                    <Grid item xs={12} key={course.courses_id}>
                      <CourseCard course={course} />
                    </Grid>
                  ))}
                </Grid>

                <Divider sx={{ my: 4 }} />

                <Typography variant="h6" gutterBottom>
                  Other Courses
                </Typography>
                <Grid container spacing={1}>
                  {getOtherCourses().map((course) => (
                    <Grid item xs={12} key={course.courses_id}>
                      <CourseCard course={course} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Paper>

          <Snackbar 
            open={alert.open} 
            autoHideDuration={6000} 
            onClose={() => setAlert({ ...alert, open: false })}
          >
            <Alert 
              onClose={() => setAlert({ ...alert, open: false })} 
              severity={alert.severity}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CourseCardDrag;