import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../0_layout/HeaderFooter';
import { Footer } from '../0_layout/Footer';
import { Box, Container } from '@mui/material';

const RegistrationLayout = () => {
  return (
    <>
      <Box>
        <Header />
      </Box>

      <Box sx={{ 
        pt: { xs: 16, sm: 17, md: 18 },
        px: { xs: 2, sm: 4, md: 6 },
        minHeight: '100vh',
        backgroundColor: '#f8fafc'
      }}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Outlet />
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default RegistrationLayout;