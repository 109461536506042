import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from './website/1_home/Layout';
// import HomepageLayout from './website/1_homepage/HomepageLayout';
import { BookLayout } from './website/0_Book1/A_BookLayout';
import YearManagement from './website/0_Book1/E_YearManagement';
import MonthManagement from './website/0_Book1/F_MonthManagement';
import { LoginPage } from './website/7_Login/LoginPage';
import { FolderSection } from './website/Folder/FolderSection';
import  AboutLayout  from './website/2_about/AboutLayout';
import TeachersOfferings from './website/3_Offerings/TeacherOfferingLayout';
import SchoolsOfferings from './website/3_Offerings/SchoolsOfferingsLayout';
import ContactLayout from './website/6_Contact/ContactLayout';
import CareerLayout from './website/5_Career/CareerLayout';
import JobDetails from './website/5_Career/JobDetails';
import EventLayout from './website/4_Events/EventLayout';
import  StudentscbseOfferings  from './website/3_Offerings/StudentsCBSELayout';
import  StudentscodingOfferings  from './website/3_Offerings/StudentsCodingLayout';
import CourseDetails from './website/3_Offerings/CourseDetails';
import RegistrationLayout from './website/0_Registration/RegistrationLayout'
import RegistrationForm from './website/0_Registration/Registration';

// Admin Side Routes 

import DashboardPage from './website/Admin_Pages/AdminHomepage';
import CreateCoursePage from './website/Admin_Pages/Create_Course'
import CarouselDrag from './website/Admin_Pages/CarouselDrag';
import ColorCardDrag from './website/Admin_Pages/ColorCardDrag';
import CourseCardDrag from './website/Admin_Pages/CourseCardDrag';

import DashboardLayoutNavigationActions from './website/7_Login/1_Admin/AdminDashboard';
import { ProtectedRoute } from './website/7_Login/1_Admin/ProtectedRoutes';
import { Test } from './website/0_layout/Test1';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' >
    <Route path='' element={<Layout/>} >    </Route>   
    <Route path='/testing' element={<Test/>} >    </Route>   
    <Route path='/home' element={<Layout/>} >    </Route>

    <Route path='/about' element={<AboutLayout/>} >    </Route>

    <Route path='/offerings/teachers' element={<TeachersOfferings/>} >    </Route>
    <Route path='/offerings/schools' element={<SchoolsOfferings/>} >    </Route>
    <Route path='/offerings/students/coding' element={<StudentscodingOfferings/>} >    </Route>
    <Route path='/offerings/students/cbse-subjects' element={<StudentscbseOfferings/>} >    </Route>
    <Route path='/courses-details/:coursesid' element={<CourseDetails/>} >    </Route>
    <Route path='/registration' element={<RegistrationLayout />}>
      <Route path=':coursesid' element={<RegistrationForm />} />
    </Route>
    <Route path='/events' element={<EventLayout/>} >    </Route>
    <Route path='/career' element={<CareerLayout/>} >    </Route>

    <Route path='/career/:jobrole' element={<JobDetails/>} >    </Route>

    <Route path='/contact' element={<ContactLayout/>} >    </Route>
    

    <Route path='/book' element={<BookLayout/>} > </Route>
    <Route path='/crud/year' element={<YearManagement/>} > </Route>
    <Route path='/crud/month' element={<MonthManagement/>} > </Route>
    <Route path='/manage-parameter' element={<YearManagement/>} > </Route>
    

    <Route path='/folder' element={<FolderSection/>}> </Route>



    <Route path='/admin-dashboard' element={<DashboardPage/>}> </Route>
    <Route path='/admin-dashboard/create-courses' element={<CreateCoursePage/>}> </Route>
    <Route path='/admin-dashboard/edit-courses/carousel-cards' element={<CarouselDrag/>}> </Route>
    <Route path='/admin-dashboard/edit-courses/color-cards' element={<ColorCardDrag/>}> </Route>
    <Route path='/admin-dashboard/edit-courses/course-cards' element={<CourseCardDrag/>}> </Route>
    <Route path='/login' element={<LoginPage/>} >    </Route>
    {/* <Route path="/admin-dashboard" element={<ProtectedRoute />}>
        <Route index element={<DashboardLayoutNavigationActions/>} />
    </Route> */}

   
    

    </Route>
    
  )


)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider  router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
