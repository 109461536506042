import React, { useEffect, useState, useRef } from 'react';
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";

const ColorCardSection = () => {
  const [offerings, setOfferings] = useState([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const cardRefs = useRef([]);

  useEffect(() => {
    const fetchOfferings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/home/offeringcards`);
        const data = await response.json();
        setOfferings(data);
      } catch (error) {
        console.error('Error fetching offerings:', error);
      }
    };
    
    fetchOfferings();
  }, []);

  useEffect(() => {
    const calculateMaxHeight = () => {
      // Reset the heights first to get true content height
      cardRefs.current.forEach(ref => {
        if (ref) {
          ref.style.height = 'auto';
        }
      });

      // Get natural heights
      const heights = cardRefs.current
        .filter(ref => ref)
        .map(ref => ref.scrollHeight);

      if (heights.length > 0) {
        const newMaxHeight = Math.max(...heights);
        
        // Apply the max height to all cards
        cardRefs.current.forEach(ref => {
          if (ref) {
            ref.style.height = `${newMaxHeight}px`;
          }
        });
        
        setMaxHeight(newMaxHeight);
      }
    };

    // Initial calculation
    const timeoutId = setTimeout(calculateMaxHeight, 300);

    // Recalculate on window resize
    window.addEventListener('resize', calculateMaxHeight);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', calculateMaxHeight);
    };
  }, [offerings]);

  const cardColors = ['#3a96d7', '#fecc00', '#78b833'];
  const starIcon = "https://www.freeiconspng.com/thumbs/star-icon/yellow-star-icon-21.png";

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container justifyContent="center">
        {cardColors.map((color, index) => (
          <Grid 
            key={index}
            item 
            px={0.25} 
            xs={12} 
            md={4} 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              position: 'relative',
              //mt: { xs: 10, md: 6 }
            }}
          >
            <Box
              sx={{
                width: "87%",
                maxWidth: 500,
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  scale: 1.05,
                  zIndex: 1
                }
              }}
            >
              {/* Avatar Circle */}
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: -70, md: -50 },
                  left: '50%',
                  transform: 'translateX(-30%)',
                  zIndex: 2,
                  bgcolor: color,
                  height: '100px',
                  width: '100px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Avatar src={starIcon} sx={{ width: 50, height: 50 }} />
              </Box>
              
              {/* Colored Card Content */}
              <Box 
                ref={el => cardRefs.current[index] = el}
                bgcolor={color}
                sx={{
                  width: "100%", 
                  borderRadius: '5px',
                  p: 3,
                  pt: 8,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  sx={{ height: '100%' }}
                  spacing={2}
                >
                  {/* Image Section */}
                  <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box  sx={{borderWidth: '10px'}}>
                    <Box
                      component="img"
                      src={offerings[index]?.imageUrl}
                      alt={offerings[index]?.courseName}
                      sx={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'contain',
                        borderRadius: '5px'                        
                      }}
                      md={{borderRadius: '5px'}}
                    />
                    </Box>
                  </Grid>

                  {/* Course Name */}
                  <Grid item>
                    <Typography variant="h5" align="center" color="white" gutterBottom>
                      {offerings[index]?.courseName || 'Loading...'}
                    </Typography>
                  </Grid>

                  {/* Description */}
                  <Grid item sx={{ flex: 1 }}>
                    <Typography variant="body1" align="center" color="white">
                      {offerings[index]?.cardsDescription || 'Loading...'}
                    </Typography>
                  </Grid>

                  {/* Buttons */}
                  <Grid item container spacing={2} justifyContent="center">
                    <Grid item>
                      <Button 
                        variant="contained" 
                        sx={{ bgcolor: 'white', color: color }}
                        href="https://superteacher.co.in/offerings/schools"
                      >
                        Know More
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button 
                        variant="contained" 
                        sx={{ bgcolor: 'white', color: color }}
                        href={offerings[index]?.enrollNow}
                      >
                        Enroll Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Box sx={{height:{xs:'50px', md:'0px'}}}  />
                </Grid>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ColorCardSection;